import React from 'react';
import Popup from '../popup/Popup';

import warning from './assets/images/warning.svg';

import './TooManyAttempts.scss';

const TooManyAttempts = () => {
  return (
    <Popup id={'tooManyAttempts'} className={'too-many-attempts'}>
      <div>
        <img src={warning} alt="warning" className={'too-many-attempts__icon'} />
        <h1 className={'too-many-attempts__title'}>
          Вы уже скачали сертификат
        </h1>
        <h4 className={'too-many-attempts__text'}>
          Попробуйте повторить попытку позже
        </h4>
      </div>
    </Popup>
  );
};

export default TooManyAttempts;
