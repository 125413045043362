import {Dispatch, SetStateAction} from 'react';

export const findLink = (text: string) => {
  const findProtocol =
    text.search(/(https|http|www)/ig);
  const findSpaceAfterLink =
    text.includes(' ', findProtocol) ? text.indexOf(' ', findProtocol) : text.length;
  const finalLink =
    text.slice(findProtocol, findSpaceAfterLink);
  const addProtocol =
    finalLink.search(/(https|http)/ig) === -1 ? `https://${finalLink}` : finalLink;

  return findProtocol !== -1 ? addProtocol : undefined;
};

export const lazyLoading = (
  lastItem: any,
  observerLoader: any,
  setScroll: Dispatch<SetStateAction<number>>,
  count: number
) => {
  const actionInSight = (entries: any) => {
    if (entries[0].isIntersecting) {
      setScroll(innerScroll => innerScroll + count);
    }
  };

  if (observerLoader.current) {
    observerLoader.current.disconnect();
  }

  observerLoader.current = new IntersectionObserver(actionInSight);
  if (lastItem.current) {
    observerLoader.current.observe(lastItem.current);
  }
};