import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {historyEKO} from 'store/components/EKOSlice';

import Title from 'components/title/Title';
import HistoryEKO from '../historyEKO/HistoryEKO';
import ActivationEKO from '../activationEKO/ActivationEKO';

import './EKOTabs.scss';

interface OwnProps {
  tabs: {
    child: any,
    name: string,
  }[],
  changeActiveTab: (prop: number) => void,
  activeTab: number
}

export const EKOTabs = ({tabs, changeActiveTab, activeTab}: OwnProps) => {
  return (
    <div className={'eko-tabs'}>
      <div className={'eko-tabs__container'}>
        {tabs.map((item, index) => (
          <div
            key={index}
            onClick={() => changeActiveTab(index)}
            className={
              index === activeTab
                ? 'eko-tabs__item eko-tabs__item_active'
                : 'eko-tabs__item'
            }>
            {item.name}
          </div>
        ))}
      </div>
      <div className={'eko-tabs__content'}>
        {tabs[activeTab].child}
      </div>
    </div>
  );
};

export const EKO = () => {
  const [active, setActive] = useState(0);

  const dispatch = useDispatch();

  const tabBar = [
    {name: 'Активация ЕКО', child: <ActivationEKO />},
    {name: 'История активаций ЕКО', child: <HistoryEKO />},
  ];

  useEffect(() => {
    dispatch(historyEKO());
  }, []);

  const changeActiveTab = (index: number) => {
    setActive(index);
  };

  return (
    <div>
      <Title>Единая карта оплаты</Title>
      <EKOTabs changeActiveTab={changeActiveTab} tabs={tabBar} activeTab={active}/>
    </div>
  );
};

