import React, {useState} from 'react';
import { useParams } from 'react-router-dom';

import LogoZaPodarkom from './assets/images/LogoZaPodarkom.svg';

import Balloon from './assets/components/Balloon';
import GiftBox from './assets/components/GiftBox';

import './GiftPage.scss';

const GiftPage: React.FC = () => {
  const {guid} = useParams<any>();
  const [showLogo,setShowLogo] = useState<boolean>(true);
  return (
    <div className='container'>
      <div className={`gift__logo-container ${showLogo? 'gift__logo-container_show' : 'gift__logo-container_hide'}`}>
        <img className={'gift__logo'} src={LogoZaPodarkom} />
      </div>
      <div className={'gift__container'}>
        <Balloon />
        <Balloon />
        <Balloon />
        <Balloon />
        <Balloon />
        <GiftBox setShowLogo={setShowLogo} guid={guid} />
      </div>
    </div>
  );
};

export default GiftPage;
