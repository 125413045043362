import React from 'react';


const TipContent = () => {
  return (
    <div
      className={'activation-eko__tip-container activation-eko__tip-container_mobile'}>
      <p className={'activation-eko__tip-list-item'}>
        <b>
          Инструкция по пополнению счета мобильного оператора:
        </b>
      </p>
      <ul className={'activation-eko__tip-list'}>
        <li>
          Введите номер вашей карты ЕКО для проверки баланса.
        </li>
        <li>
          Подтвердите, что вы не робот.
        </li>
        <li>
          Нажмите на кнопку «Проверить баланс» для проведения проверки.
        </li>
        <li>
          Введите номер телефона, на который будет произведена оплата (без 8 или +7).
        </li>
        <li>
          Введите код карты под защитным слоем (без ЕКК).
        </li>
        <li>
          Нажмите на кнопку «Активировать».
        </li>
        <li>
          Зачисление денежных средств происходит единоразово и полностью в течение 24 часов.
        </li>
      </ul>
    </div>
  );
};

export default TipContent;
