import React from 'react';
import {useHistory} from 'react-router-dom';

import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useAppDispatch} from 'utils/hooks';

import image from './assets/images/ndfl-image.svg';

import './NDFL_Banner.scss';

const NdflBanner = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const goToHelp = (e: any) => {
    e.stopPropagation();
    history.push('/dashboard/help');
    dispatch(hidePopup('ndfl_info_popup'));
  };

  const openPopup = () => {
    dispatch(showPopup('ndfl_info_popup'));
  };

  return (
    <div onClick={openPopup} className={'ndfl-banner'}>
      <img className={'ndfl-banner__image'} src={image} alt={'image'}/>
      <div className={'ndfl-banner__text-container'}>
        <div className={'ndfl-banner__title'}>
          Что такое НДФЛ и почему его нужно платить?
        </div>
        <div className={'ndfl-banner__info'}>
          НДФЛ — это налог на доходы физических лиц.
          Взимается не только с заработной платы, но и с
          доходов, полученных через программы лояльности и других
          источников в денежном или натуральном виде. Зачем его платить?
          НДФЛ нужен для финансирования различных государственных нужд —
          медицины, образования, обороны и других сфер. Поэтому, уплачивая НДФЛ,
          вы помогаете обществу и участвуете в формировании бюджета страны.
        </div>
        <div onClick={goToHelp} className={'ndfl-banner__link'}>
          Больше информации в разделе помощь
        </div>
      </div>
    </div>
  );
};

export default NdflBanner;
