import React, {useEffect, useState} from 'react';

import {GetIcon} from 'store/enums/normalizedIcons';

import './UpwardButton.scss';

const UpwardButton = () => {
  const [scroll, setScroll] = useState<number>(0);

  const expectedScroll = window.innerHeight * 1.5;

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setScroll(scrollTop);
  };

  const slowScrollToTop = (event: any) => {
    const startTime = event.timeStamp;
    const startY = window.scrollY;
    const distance = -startY;
    const animationTime = 500;

    const animateScroll = (currentTime: any) => {
      const differenceTime = currentTime - startTime;
      const timingResult = ease(differenceTime, startY, distance, animationTime);
      window.scrollTo(0, timingResult);
      if (differenceTime < animationTime) requestAnimationFrame(animateScroll);
    };

    const ease = (time: number, startY: number, distance: number, animationTime: number) => {
      time /= animationTime / 2;
      if (time < 1) return (distance / 2) * time * time + startY;
      time--;
      return (-distance / 2) * (time * (time - 2) - 1) + startY;
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    window.onscroll = handleScroll;
  }, [scroll]);

  return (
    <div
      onClick={slowScrollToTop}
      className={`
        upward-button
        ${scroll > expectedScroll ? 'upward-button__active' : ''}
      `}>
      <GetIcon iconName={'UpwardIcon'} fill={'#000'} secondaryFill={'#000'} />
    </div>
  );
};

export default UpwardButton;
