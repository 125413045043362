import React from 'react';

import {IActivationHistory} from 'store/components/DashboardSlice';

import './ActivationHistoryItem.scss';

interface IActivationHistoryItem {
  item: IActivationHistory
}

const ActivationHistoryItem = ({item}: IActivationHistoryItem) => {
  return (
    <div className={'activation-history-item__container'}>
      <div className={'activation-history-item__number activation-history-item__text'}>
        {item.number}
      </div>
      <div className={'activation-history-item__info'}>
        <div className={'activation-history-item__nominal activation-history-item__text'}>
          {item.nominal} ₽
        </div>
        <div className={'activation-history-item__date activation-history-item__text'}>
          {item.activation_date}
        </div>
      </div>
    </div>
  );
};

export default ActivationHistoryItem;
