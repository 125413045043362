import React, {useState} from 'react';

import question from '../rejectMessage/assets/images/question.svg';

interface IMessage {
  callback: () => void
}

const Message = ({callback}: IMessage) => {

  return (
    <div className={'message__question-icon'}>
      <img src={question} onClick={callback}/>
    </div>
  );
};

export default Message;
