import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {hidePopup} from 'store/components/PopupSlice';

import Popup from 'components/popup/Popup';
import Button from 'components/button/Button';

import './DeficiencyBalance.scss';

const DeficiencyBalance = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const goBack = () => {
    history.push('cart');
    dispatch(hidePopup('deficiencyBalance'));
  };

  return (
    <Popup id={'deficiencyBalance'} className={'deficiency-balance'}>
      <div className={'deficiency-balance__container'}>
        <h2 className={'deficiency-balance__title'}>Средств на вашем счете недостаточно</h2>
        <p className={'deficiency-balance__description'}>
          Вы можете выбрать сертификатов на общую сумму в 12 000 рублей
        </p>
        <Button
          onClick={goBack}
          backgroundColor={'#4661C4'}
          borderRadius={8}
          width={198}>
          Вернуться назад
        </Button>
      </div>
    </Popup>
  );
};

export default DeficiencyBalance;