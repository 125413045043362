import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {IStore} from 'store/store';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import './CompletedVerifiedEmail.scss';

const CompletedVerifiedEmail = () => {
  const dispatch = useDispatch();
  const {search} = useLocation();

  const completedVerifiedEmail =
    useSelector((state: IStore) => state.popup.popup.completedVerifiedEmail);

  useEffect(() => {
    if (search.split('=')[1] === 'verifiedEmail') {
      dispatch(showPopup('completedVerifiedEmail'));
    } else {
      dispatch(hidePopup('completedVerifiedEmail'));
    }
  }, []);

  return (
    <Popup id={'completedVerifiedEmail'}>
      <div className={'completed-verified-email'}>
        <span className={'completed-verified-email__icon'}>
          <GetIcon iconName={'LetterVerified'} />
        </span>
        <h2 className={'completed-verified-email__title'}>Спасибо! Ваш e-mail подтвержден</h2>
        <Timer popupStatus={{completedVerifiedEmail}} />
      </div>
    </Popup>
  );
};

export default CompletedVerifiedEmail;
