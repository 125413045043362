import axios from 'axios';

export const downloadPDF = async (link: string | undefined, name = 'Сертификат') => {
  if(link){
    const elem = document.createElement('a');
    elem.href = link;
    if(link.includes('https://yadi.sk/')){
      const res = await axios.get('https://cloud-api.yandex.net:443/v1/disk/public/resources/download?public_key=' + encodeURIComponent(link));
      elem.href = res.data.href;
    }
    elem.download = `${name}.pdf`;
    elem.target = '_blank';
    document.body.appendChild(elem);
    elem.click();
    elem.remove();
  }
};
