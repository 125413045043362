import React from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {hidePopup} from 'store/components/PopupSlice';
import {GetIcon} from 'store/enums/normalizedIcons';

import Popup from 'components/popup/Popup';

import './TermsOfUse.scss';

const TermsOfUse = () => {
  const dispatch = useAppDispatch();
  const card_data = useAppSelector(state => state.prizes.card);
  const props = useAppSelector(state => state.popup.props);
  const colors = useAppSelector(state => state.settings.colors);
  const domain = useAppSelector(state => state.settings.currentDomainName);

  const closePopup = () => {
    dispatch(hidePopup('termsOfUse'));
  };

  if (!card_data) {
    return null;
  }

  return (
    <Popup id={'termsOfUse'} className={'terms-of-use'}>
      <div className={'terms-of-use__container'}>
        <h3 className={'terms-of-use__title'}>
          {
            props === 'TermsOfUse'
              ? 'Правила использования подарочного сертификата'
              : 'Ограничения на использование сертификата'
          }
        </h3>
        <p className={'terms-of-use__text'}>{props === 'TermsOfUse' ? card_data.terms_of_use : card_data.limitations}</p>
        <button className={'terms-of-use__button'}>
          <span onClick={closePopup}>Вернуться назад</span>
        </button>
      </div>
    </Popup>
  );
};

export default TermsOfUse;
