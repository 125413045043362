import React from 'react';

import {useAppSelector} from 'utils/hooks';

import {GetIcon} from 'store/enums/normalizedIcons';

import './UserField.scss';

interface UserField {
  data: string;
  onClick: () => void;
  title: string;
  isMask: boolean;
  icon?: string;
}
const UserField = ({data, onClick, title, isMask, icon = 'Pencil'}: UserField) => {
  const colors = useAppSelector(state => state.settings.colors);
  const domainName = useAppSelector(state => state.settings.currentDomainName);

  const phone = data.replace(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');

  const replacedData = isMask ? phone : data;

  return (
    <div className={'user-field__container'}>
      <div className={'user-field__info-container'}>
        {title}
        {!data
          ? (
            <>
              <span onClick={onClick} className={'user-field__data user-field__specify-email'}>Указать почту</span>
            </>
          )
          : (
            <div className={'user-field__data'}>
              {replacedData}
            </div>
          )
        }

      </div>
      {!data
        ? null
        : (
          <div className={'user-field__icon'} onClick={onClick}>
            <GetIcon iconName={icon} fill={colors[domainName].firstColorIconActive} />
          </div>
        )
      }
    </div>
  );
};

export default UserField;
