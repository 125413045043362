import React, {createRef, Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import Loading from 'react-loading';
import { useDispatch } from 'react-redux';

import { ISertificate } from 'store/components/GiftSlice';
import { useAppSelector } from 'utils/hooks';
import { getSertificates } from 'store/components/GiftSlice';
import { downloadPDF } from 'utils/downloadPDF';
import { lazyLoading } from 'utils/functions';

import LogoZaPodarkom from '../images/LogoZaPodarkom.svg';
import openGiftBox from '../images/openGiftBox.png';
import capGiftBox from '../images/gift-cap.png';
import OpenGiftBoxFront from '../images/openGiftBoxFront.png';

type LocalProps = {
  guid: string;
  setShowLogo: Dispatch<SetStateAction<boolean>>;
}

type RenderItemProps = {
  item: ISertificate;
  index: number;
}

const GiftBox = ({guid, setShowLogo}: LocalProps) => {
  const dispatch = useDispatch();

  const sertificates = useAppSelector(state => state.gift.sertificates);
  const loading = useAppSelector(state => state.gift.loading);

  const giftRef = useRef<HTMLDivElement>(null);
  const elemRef = useRef<any>();
  const lastItem = createRef<any>();
  const observerLoader = useRef<any>();

  const [presentListStyle, setPresentListStyle] = useState<string>('');
  const [contentStyle, setContentStyle] = useState<string>('');
  const [capStyle, setCapStyle] = useState<string>('');
  const [boxStyle, setBoxStyle] = useState<string>('');
  const [buttonVisible, setButtonVisible] = useState<string>('_show');
  const [scroll, setScroll] = useState<number>(Math.round(
    document.documentElement.scrollHeight / (elemRef.current?.offsetHeight || 300 ) * 4
  ));

  useEffect(() => {
    dispatch(getSertificates(guid));
  }, []);

  useEffect(() => {
    lazyLoading(lastItem, observerLoader, setScroll, 12);
  }, [lastItem]);

  const Status = ({link, status}: {link: string, status: string}) => {
    if (status === 'Отгружен' && link){
      return (
        <div onClick={() => downloadPDF(link)} className='sert__button sert__button_fulfilled'>
          <span>Скачать сертификат</span>
        </div>
      );
    }

    if (status === 'В обработке' || link) {
      return (
        <div className='sert__button sert__button_pending'>
          <div className={'sert__point'}/>
          <span>В обработке</span>
        </div>
      );
    }

    return <></>;
  };

  const RenderItem = ({item, index}: RenderItemProps) => {
    return (
      <div className='sert__container' ref={scroll - 1 === index ? lastItem : elemRef}>
        <img className='sert__image' src={item.linkLogo} />
        <p className='sert__nominal'>
          <span>{item.nominal}₽</span>
        </p>
        <p className={'sert__card-number'}>
          Карта: <span>...{item.cardNumber.slice(-(item.cardNumber.length / 2))}</span>
        </p>
        <Status status={item.status} link={item.link} />
      </div>
    );
  };

  const onPressButton = () => {
    if (loading) return;
    setButtonVisible('_hide');
    setShowLogo(false);
    setCapStyle('open');
    setBoxStyle('hide-box');
    setPresentListStyle('_animate');
    setContentStyle('_show-content');
  };

  return (
    <div ref={giftRef} className={'gift'}>
      <img className={`gift__cap ${capStyle}`} src={capGiftBox}/>
      <div className={'gift__box'}>
        <img className={`gift__box-image gift__hue-rotate ${boxStyle}`} style={{zIndex: 2}} src={openGiftBox}/>
        <div className={`gift__present-list${presentListStyle}`}  style={{zIndex: 3}}>
          <div className={`gift__content gift__content${contentStyle}`}>
            <img style={{maxWidth: '100%', marginTop: '20px'}} src={LogoZaPodarkom} />
            <div className={'gift__row'}>
              {sertificates ?
                sertificates?.slice(0, scroll + 12).map((item, index) => <RenderItem key={item.cardNumber} item={item} index={index} />)
                :
                null
              }
            </div>
          </div>
        </div>
        <img className={`gift__box-image gift__hue-rotate ${boxStyle}`} style={{zIndex: 4}} src={OpenGiftBoxFront} />
        <div onClick={onPressButton} className={`gift__gift-button gift__gift-button${buttonVisible}`}>
          {loading ?
            (<Loading type={'bars'} width={50} height={50}/>)
            :
            (<span>Получить!</span>)
          }
        </div>
      </div>
    </div>
  );
};

export default GiftBox;
