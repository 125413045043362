import { configureStore } from '@reduxjs/toolkit';

import PopupSlice, {IPopup} from './components/PopupSlice';
import AuthSlice, {IAuth} from './components/AuthSlice';
import DashboardSlice, {IDashboard} from './components/DashboardSlice';
import PrizesSlice, {IPrizes} from './components/PrizesSlice';
import CartSlice, {ICart} from './components/CartSlice';
import GiftSlice, { IGifts } from './components/GiftSlice';
import SettingSlice, {ISettings} from './components/SettingSlice';
import EKOSlice, {IEkoState} from './components/EKOSlice';
import ProfileSlice, {IProfile} from './components/ProfileSlice';
import MoneyTransferSlice , {IPayment} from './components/MoneyTransferSlice';

export interface IStore {
  popup: IPopup;
  auth: IAuth;
  profile: IProfile;
  dashboard: IDashboard;
  prizes: IPrizes;
  cart: ICart;
  gift: IGifts;
  settings: ISettings;
  eko: IEkoState;
  payments: IPayment;
}

export const store = configureStore({
  reducer: {
    popup: PopupSlice,
    auth: AuthSlice,
    profile: ProfileSlice,
    dashboard: DashboardSlice,
    prizes: PrizesSlice,
    cart: CartSlice,
    gift: GiftSlice,
    settings: SettingSlice,
    eko: EKOSlice,
    payments: MoneyTransferSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
