import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {GetIcon} from 'store/enums/normalizedIcons';
import {openLink} from 'utils/openLink';
import {IStore} from 'store/store';
import {downloadCetificate} from 'store/components/DashboardSlice';
import {showPopup} from 'store/components/PopupSlice';

import Message from '../pullBasket/assets/components/status/assets/components/message/Message';
import Button from 'components/button/Button';

import statusNew from './assets/images/new.svg';
import statusCancelled from './assets/images/cancelled.svg';
import changed from './assets/images/changed.svg';
import statusProcessing from './assets/images/processing.svg';
import statusWaitingToSent from './assets/images/waitingToSend.svg';
import statusPaid from './assets/images/paid.svg';

import './StatusHistory.scss';

interface IStatusHistory {
  status: number;
  status_label: string;
  type: string;
  link?: string;
  is_changed?: boolean;
  id?: number
}

const StatusHistory = ({status, link, status_label, type, id}: IStatusHistory) => {
  const dispatch = useDispatch();

  const colors = useSelector((state: IStore) => state.settings.colors);
  const domain = useSelector((state: IStore) => state.settings.currentDomainName);

  const {orderStatusReady: {mainColor, opacity}} = colors[domain];

  const [statusTitle, setStatusTitle] = useState({image: '', className: ''});
  const newStatus = 0; //Новый
  const pendingStatus = 1; //В обработке
  const paidStatus = 2; //Полностью отгружен
  const rollbackStatus = 3; //Откат
  const rejectStatus = 4; //Отказ
  const waitingToSent1 = 6; //Ожидает отправки
  const waitingToSent2 = 100; //Ожидает отправки
  const waitingToSent3 = 101; //Ожидает отправки
  const changedStatus = 5; //Изменен

  const showPopupStatus = () => {
    dispatch(showPopup(
      {
        popup: 'orderStatusPopup',
        props: {
          status : status ? status : -1,
          type: type,
        }
      }));
  };

  const openCertificate = () => {
    if (link && id) {
      downloadCetificate({name: 'Сертификат', id: id});
    }
  };

  useEffect(() => {
    switch (status) {
      case newStatus: {
        setStatusTitle({image: statusNew, className: 'new'});
        break;
      }
      case pendingStatus: {
        setStatusTitle({image: statusProcessing, className: 'processing'});
        break;
      }
      case paidStatus: {
        setStatusTitle({image: statusPaid, className: 'paid'});
        break;
      }
      case rejectStatus: {
        setStatusTitle({image: statusCancelled, className: 'cancelled'});
        break;
      }
      case rollbackStatus: {
        setStatusTitle({image: statusCancelled, className: 'cancelled'});
        break;
      }
      case changedStatus: {
        setStatusTitle({image: changed, className: 'changed'});
        break;
      }
      case waitingToSent1: {
        setStatusTitle({image: statusWaitingToSent, className: 'waiting-to-sent'});
        break;
      }
      case waitingToSent2: {
        setStatusTitle({image: statusWaitingToSent, className: 'waiting-to-sent'});
        break;
      }
      case waitingToSent3: {
        setStatusTitle({image: statusWaitingToSent, className: 'waiting-to-sent'});
        break;
      }
    }
  }, []);

  return (
    <div className={'status-history'}>
      {
        link && status === paidStatus
          ? (
            <Button
              icon={<GetIcon iconName={'StatusCard'} fill={mainColor} opacity={opacity} />}
              className={'status-history__button'}
              onClick={openCertificate}
              uppercase={false}>
              Скачать сертификат
            </Button>
          )
          : (
            <>
              <img className={'status-history__icon'} alt={''} src={statusTitle.image}/>
              <div className={`status-history__${statusTitle.className} status-history__text`}>
                {status_label}
              </div>
              {
                `${status}` === `${newStatus}`
                  || `${status}` === `${pendingStatus}`
                  || `${status}` === `${waitingToSent1}`
                  || `${status}` === `${waitingToSent2}`
                  || `${status}` === `${waitingToSent3}`
                  ? null
                  : (
                    <Message callback={() => showPopupStatus()}/>
                  )
              }
            </>
          )
      }
    </div>
  );
};

export default StatusHistory;
