import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import clear from './assets/images/clear.svg';

import './RejectTransfer.scss';

const RejectTransfer = () => {
  const rejectTransfer = useSelector((state: IStore) => state.popup.popup.rejectTransfer);

  return (
    <Popup id={'rejectTransfer'} className={'reject-transfer'}>
      <div className={'reject-transfer__container'}>
        <img src={clear} alt="clear"/>
        <h3 className={'reject-transfer__warning'}>Ошибка</h3>
        <h4 className={'reject-transfer__title'}>Платеж не прошел.</h4>
        <p className={'reject-transfer__subtitle'}>Похоже, это техническая ошибка, повторите попытку еще раз.</p>
        <Timer popupStatus={{rejectTransfer}} />
      </div>
    </Popup>
  );
};

export default RejectTransfer;