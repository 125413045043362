import React from 'react';

import './Title.scss';

interface ITitle {
  children: string;
}

const Title: React.FC<ITitle> = ({children}) => {
  return (
    <h1 className={'title'}>{children}</h1>
  );
};

export default Title;