import React from 'react';
import MaskedInput from 'react-text-mask';

import './Input.scss';

interface IInput {
  title?: string;
  value: string | number;
  setValue: any;
  marginBottom?: number | string;
  marginRight?: number | string;
  background?: string,
  backgroundError?: string,
  mask?: (string | RegExp)[] | null;
  type?: string;
  paddingBottom?: number | string;
  placeholder?: string;
  error?: string;
  uppercase?: boolean;
  onBlur?: any;
  onChange?: any;
  setError?: any;
  disabled?: boolean;
}

const Input: React.FC<IInput> = (props) => {
  const backgroundColorError = props.backgroundError ? props.backgroundError : 'rgb(255 202 210)';
  const backgroundColor = props.background ? props.background : '#E8EBF6';
  const inputInlineStyle = {
    marginBottom: props.marginBottom,
    marginRight: props.marginRight,
    paddingBottom: props.paddingBottom,
    border: props.error ? '1px solid #ff0000' : '',
    backgroundColor: props.error ? backgroundColorError : backgroundColor,
  };

  const onChange = (event: any) => {
    let value = event.target.value;
    const unmask = value.replace(/[^\d]/g, '');

    if (props.mask !== null && value.includes('+')) {
      if (unmask.length === 1 && unmask[1] !== '7') {
        value = value.replace(value[1], '7');

        if (event.target.value[1] !== '7') {
          value = value.replace('_', event.target.value[1]);

          event.target.focus();
          event.target.setSelectionRange(5, 5);
        }
      }

      if (unmask[0] === '8' && unmask.length === 11) {
        value = value.replace('8', '7');
      }

      if (unmask.length === 10 && unmask[0] !== '7') {
        value = 7 + unmask;
      }
    }

    props.setValue(
      props.uppercase
        ? value.toUpperCase()
        : value
    );
    props.onChange && props.onChange();
  };

  const onClick = (event: any) => {
    const target = event.target;
    const value = target.value;
    const index = value.indexOf('_');

    if (index <= target.selectionStart && index !== -1) {
      target.focus();
      target.setSelectionRange(index, index);
    }
  };

  const clearError = () => {
    if (props.error){
      props.setError && props.setError('');
    }
  };

  const maskInit =
    props.mask ||
    ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

  return (
    <div className={'input'} style={inputInlineStyle} >
      <label className={'input__container'}>
        {
          props.error
            ? (
              <span className={'input__label input__label_color-red'}>
                {props.error}
              </span>
            ) : (
              props.title &&
                <span className={'input__label'}>{props.title}</span>
            )
        }
        {
          props.mask !== null ? (
            <MaskedInput
              value={props.value}
              onChange={onChange}
              type={props.type || 'text'}
              onClick={onClick}
              placeholder={props.placeholder}
              disabled={props.disabled}
              className={`input__input ${props.error ? 'input__input_error' : ''}`}
              autoFocus={true}
              onBlur={props.onBlur}
              onInput={clearError}
              keepCharPositions={false}
              mask={maskInit}
            />
          ) : (
            <input
              type={props.type}
              disabled={props.disabled}
              value={props.value}
              placeholder={props.placeholder}
              onChange={onChange}
              style={{
                borderColor: props.error ? '#F45384' : '#B0B7D3'
              }}
              onBlur={props.onBlur}
              onInput={clearError}
              className={'input__input'}
            />
          )
        }
      </label>
    </div>
  );
};

export default Input;
