import React from 'react';

const RulesAndPers = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={'footer__rules-and-pers'}>
      <p className={'footer__years'}>
        <span className={'footer__years_display-block'}>© 2015-{currentYear} Za!podarkom.ru</span>
        <span>Универсальный электронный подарочный сертификат.</span>
      </p>
      <a href="/pers.docx" target={'_blank'} className={'footer__pers'}>Политика обработки персональных данных</a>
    </div>
  );
};

export default RulesAndPers;
