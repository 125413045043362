import React from 'react';
import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {hidePopup} from 'store/components/PopupSlice';
import {
  setActiveAvailability,
  setActiveCategory,
  setChangeSearchData,
  setLoading
} from 'store/components/PrizesSlice';

import Popup from 'components/popup/Popup';
import Button from 'components/button/Button';

import './SettingPopup.scss';

const SettingPopup = () => {
  const dispatch = useAppDispatch();

  const categories = useAppSelector(state => state.prizes.categories);
  const cardsFilterLength= useAppSelector(state => state.prizes.cardsTotal);
  const availability = useAppSelector(state => state.prizes.availability);

  const suffix = () => {
    const count = Number(`${cardsFilterLength}`[`${cardsFilterLength}`.length - 1]);
    if (count >= 5 || count === 0 || count > 10 && count <= 20 || count === undefined) {
      return 'ов';
    }
    if (count === 1) {
      return '';
    }
    return 'a';
  };

  const handleActive = (index: number, selectorType: string ) => {
    dispatch(setChangeSearchData(true));
    setTimeout(() => {
      dispatch(setChangeSearchData(false));
    }, 0);

    if(selectorType === 'categories'){
      dispatch(setActiveCategory(index));
    }
    if(selectorType === 'availability'){
      dispatch(setActiveAvailability(index));
    }
  };

  const acceptChanges = () => {
    dispatch(setLoading({name: 'prizes', value: true}));
    setTimeout(() => dispatch(setLoading({name: 'prizes', value: false})), 500);
    dispatch(hidePopup('settingPopup'));
  };

  const clearFilter = () => {
    dispatch(setChangeSearchData(true));
    setTimeout(() => {
      dispatch(setChangeSearchData(false));
    }, 0);
    dispatch(setActiveAvailability(0));
    dispatch(setActiveCategory(0));
  };

  const FilterItem = ({name, active, index, selectorType} : { name: string, active: boolean, index: number, selectorType: string }) => {
    return (
      <div
        onClick={() => handleActive(index, selectorType)}
        className={
          active
            ? 'setting-popup__filter-item setting-popup__filter-item_active'
            : 'setting-popup__filter-item'
        }>
        {name}
      </div>
    );
  };

  return (
    <Popup id={'settingPopup'} className={'setting-popup'}>
      <div className={'setting-popup__container'}>
        <div className={'setting-popup__header'}>
          <div className={'setting-popup__button'}>
            <div onClick={clearFilter} className={'setting-popup__button-title'}>
              Очистить
            </div>
          </div>
          <div className={'setting-popup__header-title'}>
            <div>
              Фильтры
            </div>
          </div>
          <div/>
        </div>

        <div className={'setting-popup__content'}>
          <div className={'setting-popup__filter-group'}>
            <div className={'setting-popup__filter'}>
              <div className={'setting-popup__filter-title'}>
                В наличии
              </div>
              <div className={'setting-popup__filter-list'}>
                {availability.map((item, index) => {
                  return (
                    <FilterItem
                      index={index}
                      selectorType={'availability'}
                      key={item.name}
                      active={item.active}
                      name={item.name}
                    />
                  );
                })}
              </div>
            </div>
            <div className={'setting-popup__filter'}>
              <div className={'setting-popup__filter-title'}>
                Категории
              </div>
              <div className={'setting-popup__filter-list'}>
                {categories.map((item, index) => {
                  return (
                    <FilterItem
                      index={index}
                      selectorType={'categories'}
                      key={item.name}
                      active={item.active}
                      name={item.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <Button
            width={'90%'}
            fontSize={'13px'}
            className={'setting-popup__button-component'}
            onClick={acceptChanges}>
            Показать {`${cardsFilterLength ? cardsFilterLength : 0}`} бренд{suffix()}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default SettingPopup;
