import React from 'react';

import Button from 'components/button/Button';

import './UserManual.scss';

const UserManual: React.FC = () => {
  const handleDownloadPDF = () => {
    const link = document.createElement('a');
    
    link.href = '/userManualRules.pdf';
    link.download = 'userManualRules.pdf';  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="user-manual__container">
      <Button className={'user-manual__button'} onClick={handleDownloadPDF}>Скачать мануал<br />пользователя</Button>
    </div>
  );
};

export default UserManual;