import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import like from './assets/images/like.svg';

import './CompletedTransfer.scss';

const CompletedTransfer = () => {
  const completedTransfer = useSelector((state: IStore) => state.popup.popup.completedTransfer);
  return (
    <Popup id={'completedTransfer'} className={'completed-transfer'}>
      <div className={'completed-transfer__container'}>
        <img src={like} alt="like" className={'completed-transfer__icon'}/>
        <h2 className={'completed-order__title'}>Спасибо! Платеж проведен успешно.</h2>
        <Timer popupStatus={{completedTransfer}} />
      </div>
    </Popup>
  );
};

export default CompletedTransfer;