import React from 'react';

import './CardData.scss';

interface CardDataProps {
  date: string;
  balance: number;
}
const CardData = ({date, balance}: CardDataProps) => {
  return (
    <div className={'card-data'}>
      <div className={'card-data__info-container'}>
        Баланс:&nbsp;
        <span className={'card-data__info-data'}>
          {balance}
        </span>
      </div>
      <div className={'card-data__info-container'}>
        Действительна до:&nbsp;
        <span className={'card-data__info-data'}>
          {date}
        </span>
      </div>
    </div>
  );
};

export default CardData;
