import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {getSearchForOtherProject, useAppDispatch} from 'utils/hooks';

import {IStore} from 'store/store';
import {hidePopup} from 'store/components/PopupSlice';

import close from './assets/image/close.svg';

import './Popup.scss';

interface IPopup {
  children: any;
  id: string;
  className?: string;
  onClose?: () => void;
}

const Popup: React.FC<IPopup> = ({children, id, className, onClose}) => {
  const dispatch = useAppDispatch();
  const {search} = useLocation();
  const visiblePopup = useSelector((state: IStore) => state.popup.popup);

  const joinClassName =
    [visiblePopup[id] ? 'popup popup_active' : 'popup', className].join(' ');

  const [visibleClosePopupForOtherProject, setVisibleClosePopupForOtherProject] = useState<boolean>(false);

  const closePopup = () => {
    if (!visibleClosePopupForOtherProject) {
      dispatch(hidePopup(id));
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (visiblePopup[id]) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.querySelector('.dashboard')?.classList.add('dashboard__showed-popup');
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
      document.querySelector('.dashboard')?.classList.remove('dashboard__showed-popup');
    }
  }, [visiblePopup[id]]);

  useEffect(() => {
    getSearchForOtherProject(search, setVisibleClosePopupForOtherProject);
  }, []);

  return (
    <div className={joinClassName}>
      <div
        className={
          visiblePopup[id]
            ? 'popup__content popup__content_active'
            : 'popup__content'
        }
        onClick={e => e.stopPropagation()}>
        {
          !visibleClosePopupForOtherProject
            ? <img src={close} alt="close" className={'popup__close'} onClick={closePopup} />
            : null
        }
        <div className={'popup__children'}>
          <div className={'popup__back-place-holder'} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
