import React, {createRef, useEffect, useRef, useState} from 'react';

import {useAppSelector} from 'utils/hooks';
import {lazyLoading} from 'utils/functions';

import {GetIcon} from 'store/enums/normalizedIcons';
import Button from 'components/button/Button';
import StatusHistory from '../status/StatusHistory';
import UpwardButton from 'components/upwardButton/UpwardButton';

import './PaymentsHistory.scss';

const PaymentsHistory = () => {
  const liRef = useRef<any>();
  const observerLoader = useRef<any>();
  const lastItem = createRef<any>();

  const {history} = useAppSelector(state => state.prizes.loading);
  const transactionsData = useAppSelector(state => state.payments.dataHistory);
  const historyOrders = useAppSelector(state => state.dashboard.historyOrders);
  const colors = useAppSelector((state) => state.settings.colors);
  const domain = useAppSelector((state) => state.settings.currentDomainName);

  const [step, setStep] = useState(1);
  const restrictionHistoryLen = window.innerWidth > 540 ? transactionsData.length : 5;
  const [restrictionHistory, setRestrictionHistory] = useState(transactionsData.slice(0, restrictionHistoryLen));
  const [scroll, setScroll] = useState<number>(Math.round(
    document.documentElement.scrollHeight / (liRef.current?.offsetHeight || 300)
  ));

  const visibleButton = transactionsData.length >= 6 && restrictionHistory.length < transactionsData.length;
  const disabledNewCard = step * restrictionHistoryLen >= transactionsData.length;
  const {loadButtonColor} = colors[domain];

  useEffect(() => {
    lazyLoading(lastItem, observerLoader, setScroll, 2);
  }, [lastItem]);

  if (!transactionsData) {
    return null;
  }

  useEffect(() => {
    setRestrictionHistory(transactionsData.slice(0, restrictionHistoryLen * step));
  }, [step]);

  return (
    <div className={history ? 'payments-history payments-history_hide' : 'payments-history'}>
      <h3 className={'payments-history__title'}>История оформления платежей</h3>
      <div className={'payments-history__status-notification'}>
        <GetIcon
          iconName={'InfoIcon'}
          fill={colors[domain].infoIcon}
          secondaryFill={colors[domain].infoIcon}
          width={'24'}
        />
        <div>Статусы платежей обновляются каждые 3 минуты</div>
      </div>
      <div className={'payments-history__history'}>
        {
          restrictionHistory.slice(0, scroll + 2).map((item, index) => (
            <div className={'history-item'} key={item.id} ref={scroll - 1 === index ? lastItem : liRef}>
              <div className={'history-item__title'}>№{item.id} платежа – от {new Date(item.created_at).toLocaleDateString()}</div>
              <div className={'history-item__order-sum-container'}>
                {
                  item.tax ? (
                    <p className={'history-item__order-sum'}>
                      НДФЛ:{' '}
                      <span className={'history-item__order-sum_bold'}>{item.tax}</span>
                    </p>
                  ) : null
                }
                <p className={'history-item__order-sum'}>
                  Сумма списания:{' '}
                  <span className={'history-item__order-sum_bold'}>
                    {item.amount + (item.tax ? item.tax : 0) + item.commission}
                  </span>
                </p>
              </div>
              <div className={'history-item__status-container_mobile'}>
                <div className={'history-item__data-title'}>
                  Статус заказа
                </div>
                <StatusHistory type={'payments'} status={item.status} status_label={item.status_label} />
              </div>
              <div className={'history-item__description'}>
                <div className={'history-item__line'} />
                <div className={'history-item__number'}>
                  <div className={'history-item__data-title'}>
                    Номер карты (телефона)
                  </div>
                  <div className={'history-item__data-value'}>
                    {
                      item.type === 'phone' || item.type === 'sbp'
                        ? `+7 *** ***-${item.recipient_number.substring(0, 2)}-${item.recipient_number.substring(2, 4)}`
                        : `****-****-****-${item.recipient_number}`
                    }
                  </div>
                  <div className={'history-item__payment-type_mobile'}>
                    {item.type === 'phone' ? 'телефон' : item.type === 'card' ? 'банковская карта' : 'СБП'}
                  </div>
                </div>
                <div>
                  <div className={'history-item__data-title'}>
                    Сумма перевода
                  </div>
                  <div className={'history-item__data-value history-item__data-value_bold'}>
                    {item.amount} ₽
                  </div>
                </div>
                <div>
                  <div className={'history-item__data-title'}>
                    Комиссия
                  </div>
                  <div className={'history-item__data-value history-item__data-value_colored'}>
                    {item.commission} ₽
                  </div>
                </div>
                <div className={'history-item__payment-type_desktop'}>
                  <div className={'history-item__data-title'}>
                    Тип перевода
                  </div>
                  <div className={'history-item__data-value'}>
                    {item.type === 'phone' ? 'телефон' : item.type === 'card' ? 'банковская карта' : 'СБП'}
                  </div>
                </div>
                <div className={'history-item__status-container_desktop'}>
                  <div className={'history-item__data-title'}>
                    Статус заказа
                  </div>
                  <div className={'history-item__data-value'}>
                    <StatusHistory type={'payments'} status={item.status} status_label={item.status_label} />
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      {
        visibleButton ? (
          <div className={'payments-history__button-container'}>
            <Button
              disabled={disabledNewCard}
              className={'load-button'}
              icon={<GetIcon iconName={'LoadButton'} fill={loadButtonColor} secondaryFill={loadButtonColor} />}
              onClick={() => setStep(step + 1)}>
              Показать еще
            </Button>
          </div>
        ) : null
      }
      <UpwardButton />
    </div>
  );
};

export default PaymentsHistory;
