import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import {IStore} from 'store/store';
import {clearCart, getCart} from 'store/components/CartSlice';
import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {getUser} from 'store/components/ProfileSlice';
import {fastOrder} from 'store/components/DashboardSlice';
import {stringFormatter} from 'utils/stringFormatter';

import Popup from 'components/popup/Popup';
import Button from 'components/button/Button';

import cross from './image/close.svg';

import './AcceptByWithAllOrder.scss';

interface IOwnProps {
  nominal: any[],
  markup: number,
  ndfl: number
}

const AcceptByWithAllOrder = () => {
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const cart = useAppSelector(state => state.cart.cart);
  const purse = useAppSelector(state => state.dashboard.purse);
  const popup = useAppSelector(state => state.popup.popup);

  const user = useSelector((state: IStore) => state.auth.user);
  const is_required_email = useSelector((state: IStore) => state.auth.is_required_email);

  const props = useSelector((state: IStore) => state.popup.props);

  const residualBalance = purse?.balance ? purse.balance - props.sum : 0;

  const acceptStatus = 2;

  const disabledButton = (user?.email === '' && is_required_email)
    || (!user?.status && is_required_email)
    || ((user?.email !== user?.email))
    || (purse?.is_taxable_eps ? user?.tax ? user?.tax?.status != acceptStatus : false : false)
    || loadingUser;

  const onClose = () => {
    history.push('prizes');
    dispatch(clearCart());
    dispatch(getCart());
  };

  const click = () => {
    const reqData: {ValidityPeriodNominal: string, total: number, nominal: number, guid: string} = {
      guid: props.guid,
      total: props.sum,
      nominal: props.nominal,
      ValidityPeriodNominal: ''
    };
    setLoading(true);
    dispatch(fastOrder(reqData)).then((data) => {
      setLoading(false);
      dispatch(hidePopup('acceptByWithAllOrder'));
      dispatch(hidePopup('orderCard'));
      if (data.payload.data.code === 'ok') {
        dispatch(showPopup({popup: 'completedFastOrder', props: props}));
        dispatch(getUser());
      } else if (data.payload.data.code === 'error') {
        dispatch(showPopup('rejectOrder'));
      }
    });
  };

  const back = () => {
    dispatch(hidePopup('acceptByWithAllOrder'));
  };

  useEffect(() => {
    if (popup['acceptByWithAllOrder']) {
      dispatch(getUser()).then(() => {
        setLoadingUser(false);
      });
    }
  }, [popup['acceptByWithAllOrder']]);

  const CompletedOrderListItem = ({nominal, markup, ndfl}: IOwnProps) => {
    return (
      <>
        <div className={'accept-order-item'}>
          {nominal.map((item, index) => {
            return (
              <div key={index} className={'accept-order-item__container'}>
                <div className={'accept-order-item__nominal'}>
                  Номинал
                </div>
                <div className={'accept-order-item__nominal accept-order-item_bold'}>
                  {item.count > 1 ? (
                    <span>{item.count} <img className={'accept-order-item__cross-icon'} alt={'cross'} src={cross}/> </span>
                  ) : null}
                  {stringFormatter(`${item.nominal}`)} ₽
                </div>
              </div>
            );
          })}
          {markup ? (
            <div className={'accept-order-item__container'}>
              <div className={'accept-order-item__markup'}>
                Общая комиссия
              </div>
              <div className={'accept-order-item__markup accept-order-item_bold'}>
                {stringFormatter(`${Math.floor(markup)}`)} ₽
              </div>
            </div>
          ) : null}
          {ndfl ? (
            <div className={'accept-order-item__container'}>
              <div className={'accept-order-item__nominal'}>
                НДФЛ
              </div>
              <div className={'accept-order-item__nominal accept-order-item_bold'}>
                {stringFormatter(`${Math.round(ndfl)}`)} ₽
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <Popup id={'acceptByWithAllOrder'} className={'accept-order'} onClose={onClose}>
      <div className={'accept-order__container'}>
        <h2 className={'accept-order__title'}>{props.name}</h2>
        {props.markup ? (
          <div className={'accept-order__subtitle'}>Обменивается с комиссией</div>
        ) : null}
        <div className={'accept-order-list__container'}>
          {props.nominals && props.nominals.length ? (
            <CompletedOrderListItem ndfl={props.ndfl} markup={props.markup} nominal={props.nominals}/>
          ) : null}
        </div>
        <div className={'accept-order__sum-container'}>
          Итого к списанию:&nbsp;
          <span className={'accept-order__sum'}>
            {stringFormatter(`${Math.floor(props.sum)}`)}&nbsp;
            <span className={'accept-order__sum_gray'}>₽</span>
          </span>
        </div>
        {
          residualBalance ? (
            <div className={'accept-order__warning accept-order__warning-container'}>
              После оформления заказа на балансе кошелька останется –
              <span className={'accept-order__sum accept-order_bold accept-order__warning'}>
                &nbsp;{Math.round(residualBalance)}&nbsp;
                <span className={'accept-order__sum accept-order__warning'}>₽.</span>
              </span>
              <br/>
              Их не хватит для обмена на новые сертификаты.
            </div>
          ) : null
        }
        <Button className={'accept-order__back-button'} loading={loading} onClick={back}>
          Вернуться
        </Button>
        <Button disabled={disabledButton} loading={loading} onClick={click}>
          Оформить заказ
        </Button>
      </div>
    </Popup>
  );
};

export default AcceptByWithAllOrder;
