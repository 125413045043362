import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import timerIcon from './assets/images/timer.svg';

import './TimerLeftOut.scss';

const TimerLeftOut = () => {
  const timerLeftOut = useSelector((state: IStore) => state.popup.popup.timerLeftOut);

  return (
    <Popup id={'timerLeftOut'} className={'timer-left-out'}>
      <div className={'timer-left-out__container'}>
        <img src={timerIcon} alt="timer" className={'timer-left-out__icon'}/>
        <h2 className={'timer-left-out__title'}>
          Прошло 20 минут с момента добавления сертификата в корзину
        </h2>
        <p className={'timer-left-out__description'}>
          Все сертификаты из статуса &quot;в наличии&quot; будут переведены в статус &quot;под заказ&quot;
        </p>
        <Timer popupStatus={{timerLeftOut}} />
      </div>
    </Popup>
  );
};

export default TimerLeftOut;
