import React from 'react';
import {IHistoryEkoItem} from 'store/components/EKOSlice';

import './EKOHistoryItem.scss';

interface OwnProps {
  item: IHistoryEkoItem;
}

const EKOHistoryItem = ({item: {number, nominal, created_at, recipient_number}}: OwnProps) => {
  return (
    <div className={'history-eko-item__container'}>
      <div className={'history-eko-item__number history-eko-item__text'}>
        {number}
      </div>
      <div className={'history-eko-item__info'}>
        <div className={'history-eko-item__nominal history-eko-item__text'}>
          {nominal} ₽
        </div>
        <div className={'history-eko-item__text history-eko-item__text_desktop'}>
          Телефон
        </div>
      </div>
      <div className={'history-eko-item__text history-eko-item__text_desktop history-eko-item__phone'}>
        {recipient_number}
      </div>
      <div className={'history-eko-item__text history-eko-item__text_mobile history-eko-item__phone'}>
        Телефон
        <br/>
        {recipient_number}
      </div>
      <div className={'history-eko-item__date history-eko-item__text'}>
        {new Date(created_at).toLocaleString()}
      </div>
    </div>
  );
};

export default EKOHistoryItem;
