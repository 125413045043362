import React from 'react';

import {useAppSelector} from 'utils/hooks';

import Popup from 'components/popup/Popup';

import './TestografPopup.scss';

const TestografPopup = () => {
  const popup = useAppSelector(state => state.popup.popup);
  const surveyURL = 'https://za-podarkom.testograf.ru';

  return (
    <Popup id={'testograf'} className={'testograf-popup'}>
      {popup['testograf'] ? (
        <>
          <iframe
            src={surveyURL}
            className="testograf-popup__iframe"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </>
      ) : null}

    </Popup>
  );
};

export default TestografPopup;
