import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {showPopup} from 'store/components/PopupSlice';
import {setActiveTabMenu} from 'store/components/DashboardSlice';
import {GetIcon} from '../../store/enums/normalizedIcons';
import {IStore} from 'store/store';

import TabMenuItem from './assets/components/TabMenuItem';

import './TabMenu.scss';

const TabMenu = () => {
  const href = window.location.href;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const cart = useAppSelector(state => state.cart.cart);
  const popup = useAppSelector(state => state.popup);
  const activeTabMenu = useAppSelector(state => state.dashboard.activeTabMenu);

  const colors = useAppSelector((state: IStore) => state.settings.colors);
  const domain = useAppSelector((state: IStore) => state.settings.currentDomainName);

  const length = cart.reduce((prev, curr) => prev + +curr?.amount, 0);

  const handleTabClick = (index: number) => {
    dispatch(setActiveTabMenu(index));
  };

  const CartTitle = () => (
    <>
        Корзина
      <br />
      {length > 0 ? `(${length})` : ''}
    </>
  );

  const HistoryTitle = () => (
    <>
        История
      <br/>
        заказов
    </>
  );

  const menuList = [
    {
      id: 1,
      title: 'Каталог сертификатов',
      icon: function Icon(fill: string, secondaryFill: string, opacity: string, secondaryOpacity: string) {
        return <GetIcon
          iconName={'Tile'}
          fill={fill}
          secondaryFill={secondaryFill}
          secondaryOpacity={secondaryOpacity}
          opacity={opacity}
        />;
      },
      callback: () => {
        history.push('prizes');
      }
    },
    {
      id: 2,
      title: <HistoryTitle />,
      icon: function Icon(fill: string, secondaryFill: string, opacity: string, secondaryOpacity: string) {
        return <GetIcon
          iconName={'BurgerMini'}
          fill={fill}
          secondaryFill={secondaryFill}
          secondaryOpacity={secondaryOpacity}
          opacity={opacity}
        />;
      },
      callback: () => {
        history.push('history');
      }
    },
    {
      id: 3,
      title: <CartTitle />,
      icon: function Icon(fill: string, secondaryFill: string, opacity: string, secondaryOpacity: string) {
        return <GetIcon
          iconName={'Cart'}
          fill={fill}
          secondaryFill={secondaryFill}
          opacity={opacity}
          secondaryOpacity={secondaryOpacity}
        />;
      },
      callback: () => {
        history.push('cart');
      }
    },
    {
      id: 4,
      title: 'Помощь',
      icon: function Icon(fill: string, secondaryFill: string, opacity: string, secondaryOpacity: string) {
        if (domain === 'mascoglass') {
          return <GetIcon
            iconName={'Help'}
            fill={fill}
            secondaryFill={secondaryFill}
            opacity={opacity}
            secondaryOpacity={secondaryOpacity}
            className={'icon-help'}
          />;
        } else {
          return <GetIcon
            iconName={'HelpNew'}
            fill={fill}
            secondaryFill={secondaryFill}
            opacity={opacity}
            secondaryOpacity={secondaryOpacity}
          />;
        }
      },
      callback: () => {
        history.push('help');
      }
    },
    {
      id: 5,
      title: 'Активация',
      icon: function Icon(fill: string, secondaryFill: string, opacity: string, secondaryOpacity: string) {
        return <GetIcon
          iconName={'LogoOpacity'}
          fill={fill}
          secondaryFill={secondaryFill}
          opacity={opacity}
          secondaryOpacity={secondaryOpacity}
        />;
      },
      callback: () => {
        dispatch(showPopup('activationCard'));
      }
    }
  ];

  const changeActivity = () => {
    const pathname =  history.location.pathname.split('/');
    const currLocation = pathname[pathname.length - 1];

    switch (currLocation) {
      case 'prizes':
        handleTabClick(0);
        break;
      case 'help':
        handleTabClick(3);
        break;
      case 'history':
        handleTabClick(1);
        break;
      case 'cart':
        handleTabClick(2);
        break;
    }
  };

  useEffect(() => {
    changeActivity();
  }, []);

  useEffect(() => {
    if (popup && popup.popup['activationCard']) {
      handleTabClick(4);
    } else {
      changeActivity();
    }
  }, [popup]);

  return (
    <div className="tab-menu__bottom-navigation">
      <div className="tab-menu__navigation">
        {menuList.map((tab, index) => {
          const activeTab = index === activeTabMenu && (!href.includes('profile'));

          const mainColor = activeTab
            ? colors[domain].tabMenu.activeMainColor
            : colors[domain].tabMenu.mainColor;
          const secondaryColor = activeTab
            ? colors[domain].tabMenu.activeSecondColor
            : colors[domain].tabMenu.secondColor;

          return (
            <TabMenuItem
              setActive={handleTabClick}
              Icon={() => tab.icon(mainColor, secondaryColor, colors[domain].tabMenu.opacity, colors[domain].tabMenu.secondaryOpacity)}
              title={tab.title}
              active={activeTab}
              index={index}
              key={tab.id}
              callback={tab.callback}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TabMenu;
