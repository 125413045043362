import React from 'react';

import './InnerTabs.scss';

interface IInnerTab {
  tabs: {
    child: any,
    name: string,
    hidden?: boolean,
    onClick?: () => void
  }[],
  changeActiveTab: (prop: number) => void,
  activeTab: number
}

const InnerTabs = ({tabs, changeActiveTab, activeTab}: IInnerTab) => {
  return (
    <div className={'inner-tabs'}>
      <div className={'inner-tabs__container'}>
        {tabs.map((item, index) => {
          if (item.hidden) {
            return null;
          }

          const click = () => {
            changeActiveTab(index);
            item.onClick && item.onClick();
          };

          return (
            <div
              key={index}
              onClick={click}
              className={
                index === activeTab
                  ? 'inner-tabs__item inner-tabs__item_active'
                  : 'inner-tabs__item'
              }>
              {item.name}
            </div>
          );
        })}
      </div>
      <div className={'inner-tabs__content'}>
        {tabs[activeTab].child}
      </div>
    </div>
  );
};

export default InnerTabs;
