import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {hidePopup} from 'store/components/PopupSlice';

import Popup from 'components/popup/Popup';
import Button from 'components/button/Button';

import boom from './assets/images/boom.svg';
import './InsufficientData.scss';

const InsufficientData = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const click = () => {
    dispatch(hidePopup('insufficientData'));
    dispatch(hidePopup('orderCard'));
    history.push('profile');
  };

  return (
    <Popup id={'insufficientData'} className={'insufficient-data'}>
      <div className={'insufficient-data__container'}>
        <img src={boom} alt="like" className={'insufficient-data__icon'}/>
        <h2 className={'insufficient-data__title'}>Данных не хватает</h2>
        <div className={'insufficient-data__subtitle'}>Не указан или не подтвержден ваш адрес e-mail</div>
        <Button onClick={click}>
          Перейти в личный кабинет
        </Button>
      </div>
    </Popup>
  );
};

export default InsufficientData;
