import React, {Dispatch, SetStateAction, useState} from 'react';

import search from './assets/image/search.svg';

import './Search.scss';

interface ISearch {
  marginBottom?: string | number;
  value?: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const Search: React.FC<ISearch> = ({marginBottom, setValue, value}) => {
  const inlineStyleSearch = {
    marginBottom: marginBottom,
  };

  const onChange = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <div className={'search'}>
      <div className={'search__input-container'} style={inlineStyleSearch}>
        <input
          type="text"
          placeholder={'Поиск по каталогу'}
          className={'search__input'}
          value={value ? value : ''}
          onChange={onChange}
        />
        <img src={search} alt="search" className={'search__icon'} />
      </div>
    </div>
  );
};

export default Search;
