
export const GenerateTokenCaptcha = async ({ siteKey, action }: {siteKey: string, action: string}) => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(siteKey, { action: action }).then((token: string) => {
        resolve(token);
      }).catch(reject);
    });
  });
};
