import React from 'react';
import {useHistory} from 'react-router-dom';

import './MoneyTransferButton.scss';

const MoneyTransferButton = () => {
  const history = useHistory();

  return (
    <div
      className={'money-transfer-button'}
      onClick={() => history.push('money-transfer')}>
      <div className={'money-transfer-button__container'}>
        <div className={'money-transfer-button__icon'} />
        <p className={'money-transfer-button__title'}>
          Перевод баллов на банковскую карту
        </p>
      </div>
    </div>
  );
};

export default MoneyTransferButton;
