import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import ax from 'utils/ax';

export interface ISertificate {
  cardName: string;
  link: string;
  nominal: string;
  cardNumber: string;
  linkLogo: string;
  status: string;
}

export interface IGifts {
  sertificates: ISertificate[];
  loading: boolean;
}

export const getSertificates = createAsyncThunk(
  'gifts/getSertificates',
  async (payload: any) => {
    const requestData = {
      guid: payload
    };
    try {
      const {data} = await ax().post(
        'api/get-order-certificate-links',
        requestData
      );
      const {result} = data.data;
      const removed = result[0];

      if (data.code === 'error') {
        return [];
      } else {
        return removed;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

const GiftsSlice = createSlice({
  name: 'gift',
  initialState: {
    sertificates: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSertificates.fulfilled, (state: IGifts, {payload}) => {
      state.sertificates = payload;
      state.loading = false;
    });
    builder.addCase(getSertificates.pending, (state: IGifts) => {
      state.loading = true;
    });
    builder.addCase(getSertificates.rejected, (state: IGifts) => {
      state.loading = false;
    });
  },
});

export default GiftsSlice.reducer;

