import React from 'react';
import {useHistory} from 'react-router-dom';

import {GetIcon} from '../../store/enums/normalizedIcons';

import './GoBack.scss';

const GoBack = ({onClick}: {onClick?: () => void}) => {
  const history = useHistory();

  const click = () => {
    if (onClick) {
      onClick();
    } else {
      history.goBack();
    }
  };
  return (
    <div onClick={click} className={'go-back-button__go-back'}>
      <GetIcon iconName={'ArrowLeft'} fill={'#444444'} />
      Hазад
    </div>
  );
};

export default GoBack;
