import React from 'react';
import {useSelector} from 'react-redux';

import {IStore} from 'store/store';

import Popup from 'components/popup/Popup';
import Timer from 'components/timer/Timer';

import like from './assets/images/like.svg';

import './CompletedActivationCard.scss';

const CompletedActivationCard = () => {
  const completedActivationCard =
    useSelector((state: IStore) => state.popup.popup.completedActivationCard);

  return (
    <Popup id={'completedActivationCard'} className={'completed-activation-card'}>
      <div className={'completed-activation-card__container'}>
        <img src={like} alt="like" className={'completed-activation-card__icon'}/>
        <h2 className={'completed-activation-card__title'}>Баланс вашего кошелька успешно пополнен</h2>
        <Timer popupStatus={{completedActivationCard}} />
      </div>
    </Popup>
  );
};

export default CompletedActivationCard;
