import React from 'react';
import ReactLoading from 'react-loading';

import {useAppSelector} from 'utils/hooks';

import './WindowLoading.scss';

interface IWindowLoading {
  id: string;
  className?: string;
  size?: number;
}

const WindowLoading: React.FC<IWindowLoading> = ({id, className, size = 40}) => {
  const loading = useAppSelector(state => state.prizes.loading);

  return (
    <div
      className={`
        window-loading 
        ${loading[id] ? 'window-loading_active' : ''} 
        ${className}
      `}>
      <ReactLoading type={'spin'} color={'#4661c4'} width={size} height={size} />
    </div>
  );
};

export default WindowLoading;
