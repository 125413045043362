import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {hidePopup} from 'store/components/PopupSlice';

import './Timer.scss';

interface ITimer {
  popupStatus: {
    [key: string]: boolean;
  };
  time?: number;
  onClose?: () => void
}

const Timer: React.FC<ITimer> = ({popupStatus, time, onClose}) => {
  const dispatch = useDispatch();

  const [timer, setTimer] = useState<number>(time || 15);

  const keyPopupStatus = Object.keys(popupStatus)[0];

  useEffect(() => {
    if (popupStatus[keyPopupStatus]) {
      const counter =
        setInterval(() => setTimer(timerInner => timerInner - 1), 1000);

      if (timer < 1) {
        dispatch(hidePopup(keyPopupStatus));
        onClose && onClose();
        clearInterval(counter);
      }

      return () => clearInterval(counter);
    } else {
      setTimer(time || 15);
    }
  }, [popupStatus[keyPopupStatus], timer]);

  const timerContainer = timer < 10 ? `0${timer}` : timer;

  return (
    <div>
      <span className={'popup-timer'}>окно закроется через 00:{timerContainer}</span>
    </div>
  );
};

export default Timer;
