import React from 'react';
import {useHistory} from 'react-router-dom';

import {useAppDispatch} from 'utils/hooks';
import Popup from 'components/popup/Popup';

import {hidePopup} from 'store/components/PopupSlice';

import image from './assets/images/ndfl-image.svg';

import './NDFL_Popup.scss';

const NdflPopup = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const goToHelp = () => {
    history.push('/dashboard/help');
    dispatch(hidePopup('ndfl_info_popup'));
  };

  return (
    <Popup id={'ndfl_info_popup'}>
      <div className={'ndfl_info_popup__container'}>
        <img src={image} alt={image} />
        <div className={'ndfl_info_popup__title'}>
          Что такое НДФЛ и почему его нужно платить?
        </div>
        <div className={'ndfl_info_popup__text'}>
          НДФЛ — это налог на доходы физических лиц.
          Взимается не только с заработной платы, но и с
          доходов, полученных через программы лояльности и
          других источников в денежном или натуральном виде.
          Зачем его платить? НДФЛ нужен для финансирования различных государственных
          нужд — медицины, образования, обороны и других сфер. Поэтому, уплачивая
          НДФЛ, вы помогаете обществу и участвуете в формировании бюджета страны.
        </div>
        <div onClick={goToHelp} className={'ndfl_info_popup__link'}>
          Показать больше
        </div>
      </div>
    </Popup>
  );
};

export default NdflPopup;
