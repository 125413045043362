import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {hidePopup} from '../../store/components/PopupSlice';
import Popup from 'components/popup/Popup';

import './GoToAccount.scss';

const GoToAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToAccount = () => {
    history.push('profile');
    dispatch(hidePopup('goToAccount'));
  };

  return (
    <Popup id={'goToAccount'}>
      <div>
        <div className={'go-to-account'}>
          Для изменения данных перейдите в
          <br/>
          <span onClick={goToAccount} className={'go-to-account__link'}>
            Личный кабинет
          </span>
        </div>
      </div>
    </Popup>
  );
};

export default GoToAccount;
