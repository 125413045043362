import React from 'react';
import MaskedInput from 'react-text-mask';

import './SecondInput.scss';

interface IInputRegister {
    title?: string;
    value: string;
    setValue: any;
    marginBottom?: number | string;
    marginRight?: number | string;
    requery?: boolean;
    type?: string;
    maxLength?: number;
    uppercase?: boolean;
    isMask?: boolean;
    mask?: (string | RegExp)[] | ((value: string) => (string | RegExp)[]) | null;
    onBlur?: any;
    onChange?: any;
    error?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    errorColor?: string;
    errorBG?: string;
}

const SecondInput: React.FC<IInputRegister> = (props) => {
  const inputContainerInlineStyle = {
    marginBottom: props.marginBottom,
    marginRight: props.marginRight,
  };

  const onChange = (event: any) => {
    let value = event.target.value;
    const unmask = value.replace(/[^\d]/g, '');

    if (props.mask !== null && value.includes('+')) {
      if (unmask.length === 1 && unmask[1] !== '7') {
        value = value.replace(value[1], '7');

        if (event.target.value[1] !== '7') {
          value = value.replace('_', event.target.value[1]);

          event.target.focus();
          event.target.setSelectionRange(5, 5);
        }
      }

      if (unmask[0] === '8' && unmask.length === 11) {
        value = value.replace('8', '7');
      }

      if (unmask.length === 10 && unmask[0] !== '7') {
        value = 7 + unmask;
      }
    }

    props.uppercase
      ? props.setValue(value.toUpperCase())
      : props.setValue(value);

    props.onChange && props.onChange();
  };

  const onClick = (event: any) => {
    const target = event.target;
    const value = target.value;
    const index = value.indexOf('_');

    if (index <= target.selectionStart && index !== -1) {
      target.focus();
      target.setSelectionRange(index, index);
    }
  };

  const maskInit = props.mask
    ? props.mask
    : ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

  return (
    <div className={`second-input ${props.className}`} style={inputContainerInlineStyle}>
      <label className={'second-input__container'}>
        {
          props.title
            ? (
              <span className={'second-input__label'}>
                {props.title}
                {' '}
                {props.requery && <span className={'second-input__label_color-pink'}>*</span>}
              </span>
            )
            : null
        }
        {
          props.isMask ? (
            <MaskedInput
              mask={maskInit}
              disabled={props.disabled}
              style={{
                borderColor: props.error ? props.errorColor ? props.errorColor : '#F45384' : '#B0B7D3',
                color: props.error ? props.errorBG ? props.errorBG : '#F45384' : '#30303A',
                backgroundColor: props.error ? props.errorColor ? props.errorColor : 'rgba(244,83,132,0.15)' : '#ffffff',
              }}
              className={'second-input__input'}
              placeholder={props.placeholder}
              value={props.value}
              onClick={props.isMask ? onClick : undefined}
              onBlur={props.onBlur}
              onChange={onChange}
            />
          ) : (
            <input
              type={props.type}
              disabled={props.disabled}
              value={props.value}
              placeholder={props.placeholder}
              onChange={onChange}
              style={{
                textTransform: props.uppercase ? 'uppercase' : 'none',
                borderColor: props.error ? props.errorColor ? props.errorColor : '#F45384' : '#B0B7D3',
                color: props.error ? props.errorBG ? props.errorBG : '#F45384' : '#30303A',
                backgroundColor: props.error ? props.errorColor ? props.errorColor : 'rgba(244,83,132,0.15)' : '#ffffff',
              }}
              onBlur={props.onBlur}
              className={'second-input__input'}
              maxLength={props.maxLength ? props.maxLength : 524288}
            />
          )
        }
        <span
          className={
            props.error
              ? 'second-input__error second-input__error_active'
              : 'second-input__error'
          }>
          {props.error}
        </span>
      </label>
    </div>
  );
};

export default SecondInput;
