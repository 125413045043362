import React from 'react';

import {useAppDispatch, useAppSelector} from 'utils/hooks';
import {setVisibleMenu} from 'store/components/DashboardSlice';

import MenuItem from './assets/components/menuItem/MenuItem';

import cross from './assets/image/cross.svg';

import './Menu.scss';

const Menu = () => {
  const dispatch = useAppDispatch();
  
  const visibleMenu = useAppSelector(state => state.dashboard.visibleMenu);
  
  const hideMenu = () => {
    dispatch(setVisibleMenu(false));
  };
  
  return (
    <div className={'menu'}>
      <div
        className={
          visibleMenu
            ? 'menu__container menu__container_active'
            : 'menu__container'
        }
        onClick={hideMenu}>
        <ul
          onClick={e => e.stopPropagation()}
          className={
            visibleMenu
              ? 'menu__content menu__content_active'
              : 'menu__content'
          }>
          <li className={'menu__header'}>
            <img
              src={cross}
              alt="cross"
              onClick={hideMenu}
              className={'menu__cross'}
            />
          </li>
          <MenuItem />
        </ul>
      </div>
    </div>
  );
};

export default Menu;
