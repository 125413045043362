import React from 'react';

import './Tip.scss';

interface ITip {
  children: any;
  visible: boolean;
  className: string;
}

const Tip: React.FC<ITip> = ({children, visible, className}) => {
  return (
    <div className={[visible ? 'tip tip__show' : 'tip', className].join(' ')}>
      {children}
    </div>
  );
};

export default Tip;