import React from 'react';

import BalloonWithBack from '../images/BalloonWithBack.png';

const Balloon: React.FC = () => {
  const randInt = (from: number, to: number) => {
    return Math.floor(Math.random() * (to - from)) + from;
  };

  const style = ({transform, zIndex, blur}: any) => {
    return {
      filter: `blur(${blur}px)`,
      left: `${(randInt(0, 10) - 1) * 10}%`, 
      top: `${(randInt(10, 50)) * 10}%`, 
      animationDuration: `${(randInt(30, 50)) * 10 / 25}s`,
      transform: `scale(${transform})`,
      zIndex,
    };
  };

  return (
    <>
      <img className={'balloon'} src={BalloonWithBack} style={style({zIndex: 1, transform: 0.8, blur: 10})}/>
      <img className={'balloon'} src={BalloonWithBack} style={style({zIndex: 2, transform: 1, blur:5})}/>
      <img className={'balloon'} src={BalloonWithBack} style={style({zIndex: 3, transform: 1.2, blur: 0})}/>
    </>
  );
};

export default Balloon;
