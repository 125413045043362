import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {GetIcon} from 'store/enums/normalizedIcons';
import Button from 'components/button/Button';

import {setLoading} from 'store/components/PrizesSlice';
import {getHistoryOrders} from 'store/components/DashboardSlice';
import {useAppDispatch} from 'utils/hooks';

import './EmptyBasket.scss';

const EmptyBasket = ({stepLen}: {stepLen: number}) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const goToPrizes = () => {
    history.push('prizes');
  };

  return (
    <div className={'empty-basket'}>
      <p className={'empty-basket__info'}>У вас нет заказов</p>
      <p className={'empty-basket__prompt'}>Выберите подарочный сертификат себе или в подарок.</p>
      <Button
        icon={<GetIcon iconName={'Tile'} />}
        className={'empty-basket__button'}
        onClick={goToPrizes}>
        Выбрать сертификат
      </Button>
    </div>
  );
};

export default EmptyBasket;
