import React from 'react';

import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useAppDispatch} from 'utils/hooks';

import Button from 'components/button/Button';
import Popup from 'components/popup/Popup';

import card from './assets/images/card.svg';

import './NoWallet.scss';

const NoWallet = () => {
  const dispatch = useAppDispatch();

  const activateCard = () => {
    dispatch(showPopup('activationCard'));
    dispatch(hidePopup('noWallet'));
  };

  return (
    <Popup id={'noWallet'} className={'no-wallet'}>
      <div className={'no-wallet__container'}>
        <h3 className={'no-wallet__title'}>
          Ваш сертификат{'\n'}
          не активирован
        </h3>
        <p className={'no-wallet__description'}>
          Для того, что бы увидеть список доступных сертификатов, активируйте вашу карту
        </p>
        <Button
          backgroundColor={'#4661C4'}
          borderRadius={8}
          marginBottom={16}
          onClick={activateCard}
          uppercase={false}
          width={244}
          icon={card}>
					Активировать карту
        </Button>
      </div>
    </Popup>
  );
};

export default NoWallet;