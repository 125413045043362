import React from 'react';

import check from './assets/images/check.svg';
import {GetIcon} from 'store/enums/normalizedIcons';

import './Checkbox.scss';

interface ICheckbox {
  checkbox: boolean;
  onClick?: any;
  className?: string;
}

const Checkbox: React.FC<ICheckbox> = ({checkbox, onClick, className}) => {

  return (
    <div
      className={[
        checkbox
          ? 'checkbox checkbox_active'
          : 'checkbox',
        className
      ].join(' ')}
      onClick={onClick}>
      <div
        className={
          checkbox
            ? 'checkbox__icon'
            : 'checkbox__icon checkbox__icon_hide'
        }>
        <GetIcon iconName={'Check'} fill={''} />
      </div>
    </div>
  );
};

export default Checkbox;
