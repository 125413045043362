import React from 'react';

import {hidePopup, showPopup} from 'store/components/PopupSlice';
import {useAppDispatch} from 'utils/hooks';

import Button from 'components/button/Button';
import Popup from 'components/popup/Popup';

import card from './assets/images/card.svg';

import './ContactManager.scss';

const ContactManager = () => {
  const dispatch = useAppDispatch();

  const activateCard = () => {
    dispatch(hidePopup('contactManager'));
    //@ts-ignore
    jivo_api.open();
    return;
  };

  return (
    <Popup id={'contactManager'} className={'contact-manager'}>
      <div className={'contact-manager__container'}>
        <h2 className={'contact-manager__title'}>
          Получение сертификата
        </h2>
        <p className={'contact-manager__description'}>
          Обратитесь в службу поддержки для получения сертификата
        </p>
        <div className={'contact-manager__color_red'} onClick={activateCard}>
          Поддержка
        </div>
      </div>
    </Popup>
  );
};

export default ContactManager;
