import React from 'react';

import './Link.scss';

interface ILink {
    children: string;
    img?: any;
    marginRight?: number;
    onClick?: any;
    classname?: string;
    color?: string;
}

const Link: React.FC<ILink> = ({
  children,
  img,
  marginRight,
  onClick,
  classname,
}) => {
  const linkInlineStyle = {
    marginRight: marginRight,
  };

  return (
    <div className={`link ${classname}`} style={linkInlineStyle} onClick={onClick}>
      {img ? img : null}
      <span className={'link__text'}>{children}</span>
    </div>
  );
};

export default Link;
