import React from 'react';

import './Banner.scss';

const Banner = () => {
  return (
    <div className={'banner'}>
      ВНИМАНИЕ!
      <br/>
      В связи со сменой юридического лица маркетплейса Wildberries, поставка электронных подарочных сертификатов
      Wildberries временно приостановлена.
      <br/>
      Вы можете выбрать ЭПС Wildberries из наличия, или выбрать сертификат другого магазина. Спасибо за понимание.
    </div>
  );
};

export default Banner;
