import React from 'react';

const Rules = () => {
  const open = (urlRules: string) => {
    window.open(urlRules, '_blank')?.focus();
  };

  return (
    <p className={'auth__rules-and-pers'}>
      При входе или регистрации вы соглашаетесь с{' '}
      <a
        onClick={() => open('/Правила-активации-new.pdf')}
        target={'_self'}
        className={'auth__rules-and-pers_color-blue'}>
        Правилами&nbsp;активации
      </a>
      <span>,&nbsp;</span>
      <a
        onClick={() => open('/Политика конфиденциальности-подпись.pdf')}
        target={'_blank'}
        className={'auth__rules-and-pers_color-blue'}>
        Политикой&nbsp;конфиденциальности
      </a>
      <span>,&nbsp;</span>
      <br/>
      <a
        onClick={() => open('/Правила-обмена-new.pdf')}
        target={'_blank'}
        className={'auth__rules-and-pers_color-blue'}>
        Правилами&nbsp;обмена
      </a>
      {' '}и{' '}
      <a
        onClick={() => open('/Тарифы-на-обмен-new.pdf')}
        target={'_blank'}
        className={'auth__rules-and-pers_color-blue'}>
        Тарифами на обмен
      </a>
    </p>
  );
};

export default Rules;
